@font-face {
  font-family: "Spartan";
  src: url("/Spartan-Medium.woff2") format("woff2"),
    url("/Spartan-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("/Spartan-Bold.woff2") format("woff2"),
    url("/Spartan-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("/Spartan-Light.woff2") format("woff2"),
    url("/Spartan-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("/Spartan-Regular.woff2") format("woff2"),
    url("/Spartan-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("/Spartan-ExtraBold.woff2") format("woff2"),
    url("/Spartan-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("/Spartan-SemiBold.woff2") format("woff2"),
    url("/Spartan-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("/Spartan-Black.woff2") format("woff2"),
    url("/Spartan-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("/Spartan-ExtraLight.woff2") format("woff2"),
    url("/Spartan-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("/Spartan-Thin.woff2") format("woff2"),
    url("/Spartan-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

em {
  @apply not-italic font-bold;
}

* {
  @apply focus:outline-none;
}
