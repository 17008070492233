.slick-next:before,
.slick-prev:before {
  content: none;
}

.slick-arrow.slick-disabled {
  @apply hidden !important;
}

.slick-prev {
  @apply left-0;
}

.slick-next {
  @apply right-0;
}

.post-image-carousel {
  &.slick-slider {
    @apply overflow-x-hidden;
  }

  .slick-track:before,
  .slick-track:after {
    content: none;
  }

  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    @apply h-full;
  }

  .slick-dots {
    bottom: 48px;
    @apply mb-4 -translate-x-1/2 ml-[50%];
  }

  .slick-dots li button:before {
    content: none;
  }

  .slick-dots li button {
    @apply bg-transparent;
    @apply w-2 h-2;
    @apply p-0;
    @apply border rounded-full border-white border-solid;
  }

  .slick-dots li button:hover {
    @apply bg-white bg-opacity-60;
  }

  li.slick-active button {
    @apply bg-white;
  }
}

.home-slideshow {
  &.slick-slider {
    @apply overflow-x-hidden;
    /* height: 80vh;
    @media (max-width: 1024px) {
      height: 70vh;
    } */
  }

  .slick-track:before,
  .slick-track:after {
    content: none;
  }

  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    @apply h-full;
  }

  .slick-next,
  .slick-prev {
    @apply bg-black bg-opacity-60;
  }

  .slick-next:hover,
  .slick-prev:hover {
    @apply bg-black bg-opacity-50;
  }

  .slick-next {
    right: -10px;
  }

  .slick-prev {
    left: -10px;
  }

  .slick-next:hover {
    right: -5px;
  }

  .slick-prev:hover {
    left: -5px;
  }

  .slick-dots {
    bottom: 0;
    @apply mb-4;
  }

  .slick-dots li button:before {
    content: none;
  }

  .slick-dots li button {
    @apply bg-transparent;
    @apply w-2 h-2;
    @apply p-0;
    @apply border rounded-full border-white border-solid;
  }

  .slick-dots li button:hover {
    @apply bg-white bg-opacity-60;
  }

  li.slick-active button {
    @apply bg-white;
  }
}

.responsive-slider {
  .slick-prev,
  .slick-next {
    @apply w-8;
    @apply h-8;
    @apply text-black;
  }

  &.products-slider {
    --width: 12.5vw;

    @media (max-width: 1280px) {
      --width: calc(100vw / 6);
    }

    @media (max-width: 1024px) {
      --width: 25vw;
    }

    @media (max-width: 768px) {
      --width: 33vw;
    }

    @media (max-width: 640px) {
      --width: 50vw;
    }

    .slick-list,
    .slick-track,
    .slick-active > div {
      max-height: calc(var(--width) * 1.65) !important;
    }
  }

  &.image-slider {
    .slick-prev,
    .slick-next {
      @apply w-8;
      @apply h-8;
      @apply text-white;
    }

    .slick-list {
      @apply h-full;

      .slick-track {
        @apply h-full;
      }

      .slick-slide > div {
        @apply h-full flex justify-center items-center;
      }
    }
  }

  &.post-image-carousel {
    .slick-prev,
    .slick-next {
      @apply w-8;
      @apply h-8;
      @apply text-black;
    }
  }

  .slick-dots {
    @apply w-auto;
    @apply pr-6;
  }
}

.not-centered-slider {
  .slick-track {
    @apply ml-0 mr-0;
  }
}

.slick-thumb {
  @apply hidden md:block !important;

  li {
    width: 4%;
    height: auto;
    min-width: 55px;
    min-height: auto;
  }

  @apply bottom-1;

  li.slick-active {
    @apply ring-4;
    @apply ring-gray-500;
  }
}
