@import "tailwindcss/base";
@import "./custom-base.css";

@import "tailwindcss/components";
@import "./custom-components.css";

@import "tailwindcss/utilities";
@import "./custom-utilities.css";

::-webkit-scrollbar-track {
  @apply bg-gray-100;
  @apply border border-white border-solid;
  @apply rounded-md;
}

::-webkit-scrollbar {
  @apply w-3;
  @apply bg-black;
}

::-webkit-scrollbar-thumb {
  @apply border border-white border-solid;
  @apply rounded-md;
  @apply bg-black;
}
