.plus-horizontal {
  @apply absolute;
  @apply h-0 w-5;
  @apply border-t border-b border-black;
  top: calc(50% - 1px);
}

.plus-vertical {
  @apply absolute;
  @apply h-5 w-0;
  @apply border-l border-r border-black;
  left: calc(50% - 1px);
}

.custom-number {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
